<template>
	<div v-if="headingImage" ref="header" :class="{ subpage: !route.name.startsWith('index') }">
		<div class="hero-image">
			<slot name="heroContent" />
			<header v-if="video" class="video-header">
				<div class="video-background">
					<div class="video-foreground">
						<iframe
							id="embed-desktop"
							:src="
								'https://youtube.com/embed/' +
								video +
								'?version=3&enablejsapi=1&html5=1&hd=1&wmode=opaque&showinfo=0&playlist=' +
								video +
								'&autoplay=1&playsinline=1&rel=0&controls=0&mute=1&loop=1&iv_load_policy=3&modestbranding=0'
							"
							frameborder="0"
							loading="lazy"
							class="youtube-player"
							allow="autoplay"
							allowfullscreen
						></iframe>
					</div>
				</div>
			</header>
			<picture v-else>
				<source
					v-if="headingImage.imagePortrait"
					:srcset="headingImage.imagePortrait"
					media="(max-width: 500px)"
				/>
				<source :srcset="headingImage.imageWebp" type="image/webp" />
				<source :srcset="headingImage.image" />
				<img class="slider-image" :src="headingImage.image" :alt="headingImage.imageAlt" />
			</picture>
			<a v-if="route.name.startsWith('index')" class="scroll-down smooth-scroll" @click="scrollDown()" />
		</div>
	</div>
</template>

<script setup>
const route = useRoute();

const header = ref(null);
const scrollDown = () => {
	const scrollHeight = header.value.clientHeight;
	window.scroll({
		top: scrollHeight,
		left: 0,
		behavior: 'smooth',
	});
};

defineProps({
	headingImage: { type: Object, default: () => {} },
	video: { type: String, default: '' },
});
</script>

<style lang="scss" scoped>
.hero-image {
	width: 100%;
	height: 100vh;
	position: relative;
	overflow: hidden;
}

.hero-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.subpage .hero-image {
	height: 50vh;
}

.video-background {
	display: block;
	z-index: -99;
}

.subpage .video-background {
	width: 100vw;
	height: calc(((101vw / 17) * 7) + 1vw);
}

.video-foreground,
.video-background iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-header {
	min-height: 100vh;
	margin-top: 0;
	overflow: hidden;
}

.video-foreground {
	width: 300%;
	left: -100%;
	height: 116%;
	top: -8%;
}

.video-background iframe {
	top: -10%;
	left: -20%;
	width: 140%;
	height: 120%;
}

header {
	overflow: hidden;
}

.hero-image::before {
	content: '';
	z-index: 2;
	position: absolute;
	display: block;
	inset: 0;
	background: rgba(0 0 0 / 10%);
}

.scroll-down {
	position: absolute;
	cursor: pointer;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: 0 auto;
	background: url('~/assets/images/icon-scroll-down.png') no-repeat center center;
	width: 40px;
	height: 40px;
	background-size: 40px;
	z-index: 2;
}

@media (max-width: 1080px) {
	header {
		min-height: 48vh;
	}
}
</style>
